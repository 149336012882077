jQuery(function($){
	let mv = getCookieValue('mv')
	const url_path = location.pathname;
	const url_query = location.search;


	//Cookieにmvが設定されていなければ付与
	if (!mv) {
	  console.log('mvは空');
	  const random_num = Math.floor(Math.random() * 3) + 1;
	  document.cookie = 'mv=0'+random_num;
	}else{
		console.log(mv);
	}
  $('#shopify-section-16188185873aa43426 .background-alignment-center').css({'background-image':'url("https://cdn.shopify.com/s/files/1/1843/5225/files/mv'+mv+'.webp")'});

  if(url_path === '/'){
  	if(url_query){
  		if (url_query.indexOf('mv') !== -1) {
	  		console.log('!== -1');
  		}else{
	  		window.location.href = url_query + '&' + 'mv' + mv;
  		}
  	}else{
  		window.location.href = '?' + 'mv' + mv;
  	}
  }


});

//Cookie取得関数
function getCookieValue(key) {
  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    var cookiesArray = cookie.split('='); 
    if (cookiesArray[0].trim() == key.trim()) { 
      return cookiesArray[1];
    }
  }
  return '';
}